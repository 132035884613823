@font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica/regular.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica/medium.woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Narrow';
    src: url('./fonts/helvetica/regular-narrow.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Narrow';
    src: url('./fonts/helvetica/medium-narrow.woff');
    font-weight: 500;
    font-style: normal;
}

@config "./tailwind.config.ts";
